import React, { useState } from 'react';
import CardContainer from '../../Components/CardsContainerForSale/CardsContainerForSale';
import FilterView from '../../Components/FilterView/FilterView';

const Sale = () => {

  return (
    <div>
   {/* <FilterView /> */}
      <CardContainer />
    </div>
  );
};

export default Sale;
